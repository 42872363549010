$gray: #616e7c;
$light-gray: #b0bac9;
$light-blue: #e2f2ff;
$lighter-blue: #e3f5ff;
$blue: #2f75f7;
$dark-gray: #3e4c59;
// $dark-green: #2f80ed;
$lighter-gray: #c4c4c4;
$deep-blue: #0a2463;
$light-red: #f8634b;
$ash: #f4f4f4;
$light-ash: #f2f2f2;
$dark-ash: #f9f9f9;
$dark-white: #9a9a9a;
$google-red: #db4437;
$face-blue: #3c5a99;
$placeholder: rgba(62, 76, 89, 0.4);
$insta-purple: #5851db;
$twitter-blue: #1da1f2;
$orange: #f8634b;
$green: #27ae60;
$darker-white: #808080;
$light-black: #565f62;
$blush-white: #eee;
$blackish: #071013;
$blash: #d2d2d2;
$mastercard-orange: #f9a000;
$mastercard-red: #ed0006;
$fair-black: #0a0d1c;
$pink: #fcd0c9;
$new-black: #2e384d;
$dark-red: #d0021b;
$white-blue: #11b4fe;
$blush-gray: #e0e7ff;
$card-input-bg: rgba(224, 231, 255, 0.2);
$input-blue: #f8f9ff;
$input-blue2: #eff4fd;
$darkest-blue: #2b5257;
$purple: #4e1681;
$last-black: #4a4847;
$brown-black: #49393b;
$dark-green: #174a41;
$light-green: #ddf5e2;
$break-point-smobile: 320px;
$break-point-mobile: 900px;
$break-point-exmobile: 426px;
$break-point-tablet: 900px;
$new-yellow: #D7C629;

@mixin mobile() {
  @media only screen and (min-width: $break-point-smobile) and (max-width: $break-point-mobile) {
    @content;
  }
}
@mixin tablet() {
  @media only screen and (min-width: $break-point-exmobile) and (max-width: $break-point-mobile) {
    @content;
  }
}

// @mixin media($types...) {
//   @each $type in $types {
//     @media #{$type} {
//       @content ($type);
//     }
//   }
// }

@function strip-unit($number) {
  @if type-of($number) == "number" and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

// Min and max view ports

@mixin fluid-type($properties, $min-value, $max-value) {
  @each $property in $properties {
    #{$property}: $min-value;
  }

  @media (min-width: $min-vw) {
    @each $property in $properties {
      #{$property}: calc(
        #{$min-value} + #{strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}
      );
    }
  }

  @media (min-width: $max-vw) {
    @each $property in $properties {
      #{$property}: $max-value;
    }
  }
}
