@import "../../assets/base.scss";

.forgot {
  height: 100vh;
  width: 100vw;

  .main {
    background: white;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;

    @include mobile {
    }

    .formGroup {
      display: flex;
      flex-direction: column;
      align-items: center;
      @include mobile {
        width: 100vw;
      }
      .logo {
        height: 70px;
        margin-bottom: 60px;
      }
      .head1 {
        font-size: 24px;
        line-height: 120%;
        color: $brown-black;
        font-weight: bold;
        margin: 10px;
        @include mobile {
          font-size: 30px;
        }
      }

      .head2 {
        color: $brown-black;
        font-size: 18px;
        line-height: 30px;
        width: 427px;
        margin: 10px;
        @include mobile {
          font-size: 16px;
          width: 95vw;
          text-align: center;
        }

        a {
          color: $dark-green;
        }
      }

      form {
        display: flex;
        flex-direction: column;
        margin: 20px 120px;
        box-sizing: border-box;

        @include mobile {
          margin: 0;
          width: 95vw;
          box-sizing: border-box;
        }
        .passInput {
          position: relative;
          width: 400px;
          @include mobile {
            width: 100%;
            box-sizing: border-box;
          }
          .inbtn {
            position: absolute;
            right: -40px;
            top: 40%;
            border: none;
            height: 20px;
            width: 20px;
            border-radius: 100%;
            outline: none;
            text-align: center;
            font-weight: bold;
            padding: 2px;
            cursor: pointer;

            &:hover {
              transform: scale(1.2);
            }
            @include mobile {
              right: 15px;
              top: 35%;
            }
          }
        }

        input {
          width: 400px;
          background: white;
          border: 1px solid $dark-white;
          border-radius: 4px;
          padding: 19px 24px;
          margin: 13px;
          font-size: 16px;
          line-height: 22px;
          color: $dark-gray;
          outline: none;

          @include mobile {
            width: 95%;
            box-sizing: border-box;
          }

          &:placeholder {
            font-size: 16px;
            line-height: 22px;
            color: rgba(62, 76, 89, 0.4);
          }

          &:focus {
            border-color: $dark-green;
          }
        }
        .submit {
          border-radius: 4px;
          margin: 13px;
          font-size: 16px;
          line-height: 22px;
          padding: 10px 50px;
          width: 450px;
          background: $dark-green;
          color: white;
          cursor: pointer;
          font-weight: bold;
          @include mobile {
            box-sizing: border-box;
            width: 95%;
            padding: 5px 50px;
          }

          &:disabled {
            background: lighten($dark-green, 10);
            cursor: no-drop;
          }
          span {
            padding: 12px 0;
          }
          img {
            height: 35px;
          }

          &:hover {
            background: lighten($dark-green, 10);
          }
        }

        .forgot {
          font-size: 16px;
          line-height: 22px;
          text-decoration: none;
          color: $dark-green;
          font-weight: bolder;
          margin: 5px 13px;
        }
      }
    }
  }
}
